//object assign polyfill
if (!Object.assign) {
	Object.defineProperty(Object, 'assign', {
	  enumerable: false,
	  configurable: true,
	  writable: true,
	  value: function(target) {
		'use strict';
		if (target === undefined || target === null) {
		  throw new TypeError('Cannot convert first argument to object');
		}
  
		var to = Object(target);
		for (var i = 1; i < arguments.length; i++) {
		  var nextSource = arguments[i];
		  if (nextSource === undefined || nextSource === null) {
			continue;
		  }
		  nextSource = Object(nextSource);
  
		  var keysArray = Object.keys(Object(nextSource));
		  for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
			var nextKey = keysArray[nextIndex];
			var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
			if (desc !== undefined && desc.enumerable) {
			  to[nextKey] = nextSource[nextKey];
			}
		  }
		}
		return to;
	  }
	});
  }

// Production steps of ECMA-262, Edition 5, 15.4.4.18
// Reference: https://es5.github.io/#x15.4.4.18

if (!Array.prototype['forEach']) {

	Array.prototype.forEach = function(callback, thisArg) {
  
	  if (this == null) { throw new TypeError('Array.prototype.forEach called on null or undefined'); }
  
	  var T, k;
	  // 1. Let O be the result of calling toObject() passing the
	  // |this| value as the argument.
	  var O = Object(this);
  
	  // 2. Let lenValue be the result of calling the Get() internal
	  // method of O with the argument "length".
	  // 3. Let len be toUint32(lenValue).
	  var len = O.length >>> 0;
  
	  // 4. If isCallable(callback) is false, throw a TypeError exception.
	  // See: https://es5.github.io/#x9.11
	  if (typeof callback !== "function") { throw new TypeError(callback + ' is not a function'); }
  
	  // 5. If thisArg was supplied, let T be thisArg; else let
	  // T be undefined.
	  if (arguments.length > 1) { T = thisArg; }
  
	  // 6. Let k be 0
	  k = 0;
  
	  // 7. Repeat, while k < len
	  while (k < len) {
  
		var kValue;
  
		// a. Let Pk be ToString(k).
		//    This is implicit for LHS operands of the in operator
		// b. Let kPresent be the result of calling the HasProperty
		//    internal method of O with argument Pk.
		//    This step can be combined with c
		// c. If kPresent is true, then
		if (k in O) {
  
		  // i. Let kValue be the result of calling the Get internal
		  // method of O with argument Pk.
		  kValue = O[k];
  
		  // ii. Call the Call internal method of callback with T as
		  // the this value and argument list containing kValue, k, and O.
		  callback.call(T, kValue, k, O);
		}
		// d. Increase k by 1.
		k++;
	  }
	  // 8. return undefined
	};
  }
  

  /*
 * forEach Polyfill
 *
 * 2015-12-27
 *
 * By Feifei Hang, http://feifeihang.info
 * Public Domain.
 * NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
 */
'use strict';
(function () {
  if (!Array.prototype.forEach) {
    Array.prototype.forEach = function forEach (callback, thisArg) {
      if (typeof callback !== 'function') {
        throw new TypeError(callback + ' is not a function');
      }
      var array = this;
      thisArg = thisArg || this;
      for (var i = 0, l = array.length; i !== l; ++i) {
        callback.call(thisArg, array[i], i, array);
      }
    };
  }
})();

if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }