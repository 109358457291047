import MarkerClusterer from '@googlemaps/markerclustererplus';

class ImageRepository {
	constructor() {
		if ($('.repoMap').length) {
			this.initRepoMap();
		}
		if ($('.tooltipContainer').length) {
			this.initTooltipContainer();
		}
	}

	initRepoMap() {
		// First, load the api
		this.apiKey = 'AIzaSyDsYVn6Gj1zHg78kWIbMWrx1f3h1-9Vwf0';
		this.repoMapContainer = $('.repoMap')[0];

		if (this.repoMapContainer.dataset.apiKey) {
			this.apiKey = this.repoMapContainer.dataset.apiKey;
		}

		this.apiKey = 'AIzaSyDqJb8zP1qD5iW8u3VD_bzjtz1T4D26zGw';

		// Create Google script
		const script = document.createElement('script');
		script.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=window.imageRepository.mapsApiLoaded`);
		document.body.appendChild(script);
	}

	mapsApiLoaded() {
		// Create map
		this.maps = window.google.maps;
		this.map = new this.maps.Map(this.repoMapContainer.querySelector('.mapContainer .map'), {
			center: {
				lat: 52.1927064,
				lng: 5.6074137
			},
			zoom: 8,
			zoomControl: true,
			mapTypeControl: false,
			scaleControl: false,
			rotateControl: false,
			streetViewControl: false,
			fullscreenControl: false,
			styles: this.getMapStyle(),
		});

		// Start loading items
		$.ajax({
			url: '/umbraco/api/ImageRepository/GetMapData',
			method: 'get',
			success: data => {

				// Create markers for each item
				let bounds = new this.maps.LatLngBounds();
				this.markers = [];

				data.Items.forEach(item => {

					let marker = new this.maps.Marker({
						map: this.map,
						position: {
							lat: parseFloat(item.Lat),
							lng: parseFloat(item.Long)
						},
						title: `Bekijk alle vondsten uit ${item.Name}`,
						icon: {
							url: '/img/svg/icon-marker-beeldbank-2.svg',
							labelOrigin: {
								y: 20,
								x: 45,
							}
						},
						label: {
							text: item.Count + '',
							color: '#ffffff',
							fontSize: '16px',
							fontWeight: '600',
							fontFamily: 'Raleway, Adelle, sans-serif'
						},
						//animation: this.maps.Animation.DROP,
					});

					marker.addListener('click', e => {
						window.location.href = '/beeldbank-archeologie-doorzoeken/overzicht?location=' + item.Name;
					});

					bounds.extend(marker.position);

					this.markers.push(marker);
					this.map.fitBounds(bounds);
				});

				// Cluster
				let copy = MarkerClusterer.prototype.constructor.CALCULATOR;
				MarkerClusterer.prototype.constructor.CALCULATOR = (markers, count) => {

					let totalCount = 0;
					markers.forEach(marker => {
						totalCount = totalCount + parseInt(marker.label.text);
					});

					let result = copy(markers, count);
					result.text = totalCount + '';

					return result;
				};

				let cluster = new MarkerClusterer(this.map, this.markers, {
					styles: [
						{
							width: 50,
							height: 50,
							className: 'custom-clustericon-1',
						},
						{
							width: 50,
							height: 50,
							className: 'custom-clustericon-2',
						},
						{
							width: 50,
							height: 50,
							className: 'custom-clustericon-3'
						}
					],
				});

				console.log(cluster);
			}
		});
	}

	getMapStyle() {
		return [{
				"featureType": "all",
				"elementType": "geometry.fill",
				"stylers": [{
						"visibility": "on"
					},
					{
						"color": "#edf4f5"
					}
				]
			},
			{
				"featureType": "all",
				"elementType": "labels.text",
				"stylers": [{
						"visibility": "on"
					},
					{
						"weight": "1.10"
					},
					{
						"color": "#052529"
					}
				]
			},
			{
				"featureType": "all",
				"elementType": "labels.text.fill",
				"stylers": [{
						"color": "#ff0000"
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "all",
				"elementType": "labels.text.stroke",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "all",
				"elementType": "labels.icon",
				"stylers": [{
					"visibility": "on"
				}]
			},
			{
				"featureType": "administrative",
				"elementType": "labels.text",
				"stylers": [{
						"weight": "1.20"
					},
					{
						"visibility": "on"
					},
					{
						"color": "#ffffff"
					}
				]
			},
			{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#021c20"
				}]
			},
			{
				"featureType": "administrative",
				"elementType": "labels.text.stroke",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "administrative.neighborhood",
				"elementType": "all",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "administrative.land_parcel",
				"elementType": "all",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [{
						"color": "#99c2c8"
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "landscape.man_made",
				"elementType": "geometry.fill",
				"stylers": [{
						"visibility": "on"
					},
					{
						"color": "#99c2c8"
					}
				]
			},
			{
				"featureType": "landscape.man_made",
				"elementType": "labels.text",
				"stylers": [{
						"color": "#043b42"
					},
					{
						"visibility": "on"
					},
					{
						"weight": "1.2"
					}
				]
			},
			{
				"featureType": "landscape.natural",
				"elementType": "geometry.fill",
				"stylers": [{
						"color": "#b0dae0"
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "landscape.natural",
				"elementType": "labels.text",
				"stylers": [{
						"color": "#006876"
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "landscape.natural",
				"elementType": "labels.text.stroke",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "road",
				"elementType": "all",
				"stylers": [{
						"saturation": -100
					},
					{
						"lightness": 45
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "labels",
				"stylers": [{
						"visibility": "simplified"
					},
					{
						"color": "#50787e"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [{
					"visibility": "on"
				}]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry.fill",
				"stylers": [{
						"color": "#dade2e"
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry.stroke",
				"stylers": [{
						"visibility": "off"
					},
					{
						"color": "#ff0000"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "labels",
				"stylers": [{
						"visibility": "off"
					},
					{
						"color": "#edf4f5"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [{
						"color": "#46bcec"
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [{
						"visibility": "on"
					},
					{
						"color": "#7eb9c6"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#cbebef"
				}]
			}
		];
	}

	initTooltipContainer() {
		this.tooltipContainer = $('.tooltipContainer')
		this.open = false;
		this.tooltipContent = $(this.tooltipContainer).find('.content');
		this.tooltipButtonText = $('.tooltipContainer .tooltipButton span');
		this.tooltipContent.slideUp();
		
		$('.tooltipContainer .tooltipButton').on('click', () => {
			if(this.open)
			{
				this.tooltipContent.slideUp();
				this.open = false;
				this.tooltipButtonText.text('Hoe werkt het?');
				this.tooltipButtonText.attr('title', 'Open het info-scherm');
			}
			else
			{
				this.tooltipContent.slideDown();
				this.open = true;
				this.tooltipButtonText.text('Sluiten');
				this.tooltipButtonText.attr('title', 'Sluit het info-scherm');
			}
		});
	}
}

$(function () {
	window.imageRepository = new ImageRepository();
});