import './primary/polyfill';
import './primary/jquery.min';
import './primary/lity.min';
import './primary/hammer.min';
import '@popperjs/core/dist/umd/popper-lite.min';
import 'tippy.js/dist/tippy-bundle.umd.min';
import './primary/main';
import 'bootstrap/js/src/index';
import './primary/jquery.cookie';
import './primary/avgController';
import './primary/imageRepository';
