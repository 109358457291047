import '@popperjs/core/dist/umd/popper-lite.min';
import tippy from 'tippy.js/dist/tippy-bundle.umd.min';

var homepageAnimation = {
	init: function () {
		if ($('.animation').length > 0) {

			if ('matchMedia' in window) {
				if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
					$('.animation').addClass("animate");
				}
			} else {
				$('.animation').addClass("animate");
			}

		}
	}
}

var homepageNewsScroller = {
	pathStr: "M.9 97.6C233.7 5.5 362-36.1 720.1 56.1c136.2 35.1 437.7 132.7 721 42.1C1673.9 6 1802.2-36.1 2160.4 56.1c136.2 35.1 437.7 132.7 721 42.1",
	path: {},
	pathLength: 0,
	items: [],
	calculatedEndPos: 0,
	calculatedStartPos: 0,
	init: function () {
		if ($(window).width() > 1024 && $('.newsHighlightScroller').length /* > 0 && $('html.safari').length <= 0*/ ) {
			homepageNewsScroller.setPathLength();
			var currentPathPercentage = 0;


			//if ($(window).width() > 1224) {
			//	currentPathPercentage = 40;
			//}
			//else {
			//	currentPathPercentage = 32;
			//}


			var windowWidth = $(window).width();

			if (windowWidth > 1000) {
				currentPathPercentage = 36;
			}

			if (windowWidth > 1100) {
				currentPathPercentage = 34;
			}

			if (windowWidth > 1300) {
				currentPathPercentage = 40;
			}

			if (windowWidth > 1400) {
				//currentPathPercentage = 0;
			}




			homepageNewsScroller.calculatedStartPos = currentPathPercentage;
			$.each($('.newsHighlightScroller .newsItem'), function (key, value) {
				var angleRandomizer = (Math.random() * 10) + 1;
				var heightRandomizer = (Math.random() * 60) - 30;
				homepageNewsScroller.setPosition(value, currentPathPercentage, angleRandomizer, heightRandomizer);
				homepageNewsScroller.items.push({
					item: value,
					currentPerc: currentPathPercentage,
					originalPerc: currentPathPercentage,
					randomAngle: angleRandomizer,
					randomHeight: heightRandomizer
				});
				if ($(window).width() > 1024) {
					currentPathPercentage += 10;
				} else {
					currentPathPercentage += 7;
				}
			});
			homepageNewsScroller.calculatedEndPos = homepageNewsScroller.calculatedStartPos - (10 * (homepageNewsScroller.items.length - 4));

			$('.newsHighlightScroller .controls a.pageNav--prev').on('click', function (e) {
				homepageNewsScroller.previous();
			});
			$('.newsHighlightScroller .controls a.pageNav--next').on('click', function (e) {
				homepageNewsScroller.next();
			});

			$(document).on('keydown', function (e) {
				switch (e.which) {
					case 37: // left
						homepageNewsScroller.previous();
						break;

					case 39: // right
						homepageNewsScroller.next();
						break;
				}
			});

			var hammer = new Hammer($('.newsHighlightScroller')[0]);
			hammer.on('swipe', function (e) {
				switch (e.direction) {
					case Hammer.DIRECTION_LEFT:
						homepageNewsScroller.next();
						break;
					case Hammer.DIRECTION_RIGHT:
						homepageNewsScroller.previous();
						break;
				}
			});

			homepageNewsScroller.disableButtons(true, false);
		} else {
			$('.newsHighlightScroller .controls').remove();
		}
	},
	setPathLength: function () {
		homepageNewsScroller.path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		homepageNewsScroller.path.setAttribute('d', homepageNewsScroller.pathStr);
		homepageNewsScroller.pathLength = homepageNewsScroller.path.getTotalLength();
	},

	setPosition: function (item, percentage, angleRandomizer, heightRandomizer) {
		var point = homepageNewsScroller.getPointAt(percentage);
		var angle = homepageNewsScroller.getAngleAt(percentage);

		angle = angle / angleRandomizer;

		item.style.cssText = "transform:translateX(" + point.x + "px) translateY(" + (point.y) + "px) rotate(" + angle + "deg);";
	},

	getPointAt: function (percent) {
		return homepageNewsScroller.path.getPointAtLength(homepageNewsScroller.pathLength * percent / 100);
	},

	getAngleAt: function (percent) {
		var before = homepageNewsScroller.getPointAt(percent - 1);
		var after = homepageNewsScroller.getPointAt(percent + 1);
		return Math.atan2(after.y - before.y, after.x - before.x) * 180 / Math.PI;
	},

	setStartingPos: function (startingPerc) {
		var currentperc = startingPerc;
		homepageNewsScroller.items.forEach(function (item) {
			item.currentPerc = currentperc;
			homepageNewsScroller.setPosition(item.item, item.currentPerc, item.randomAngle, item.randomHeight);
			currentperc += 10;
		});
	},

	scrollForward: function (scrollAmount) {
		homepageNewsScroller.items.forEach(function (item) {
			item.currentPerc -= scrollAmount;
			homepageNewsScroller.setPosition(item.item, item.currentPerc, item.randomAngle, item.randomHeight);
		});
	},
	scrollBack: function (scrollAmount) {
		homepageNewsScroller.items.forEach(function (item) {
			item.currentPerc -= scrollAmount;
			homepageNewsScroller.setPosition(item.item, item.currentPerc, item.randomAngle, item.randomHeight);
		});
	},
	disableButtons: function (left, right) {
		$('.newsHighlightScroller .controls a.pageNav--prev').toggleClass('disabled', left).attr("tabindex", left ? "-1" : "0");
		$('.newsHighlightScroller .controls a.pageNav--next').toggleClass('disabled', right).attr("tabindex", right ? "-1" : "0");
	},
	previous: function (percentage = -10) {
		homepageNewsScroller.scroll(percentage);
	},

	next: function (percentage = 10) {
		homepageNewsScroller.scroll(percentage);
	},

	scroll(percentage) {
		var disableLeft = false;
		var disableRight = false;
		homepageNewsScroller.items.forEach(function (item, index) {
			item.currentPerc -= percentage;
			if (item.currentPerc <= item.originalPerc - 50) {
				item.currentPerc = item.originalPerc - 50;
				disableRight = true;
			}

			if (item.currentPerc >= item.originalPerc) {
				item.currentPerc = item.originalPerc;
				disableLeft = true;
			}

			homepageNewsScroller.setPosition(item.item, item.currentPerc, item.randomAngle, item.randomHeight);
		});

		homepageNewsScroller.disableButtons(disableLeft, disableRight);
	},
	reset() {
		homepageNewsScroller.items.forEach(function (item) {
			item.currentPerc = item.originalPerc;
			homepageNewsScroller.setPosition(item.item, item.currentPerc, item.randomAngle, item.randomHeight);
		});
	}
}

var menu = {
	headerHeight: 0,
	headerOffset: 0,
	init: function () {
		//$('.openMenu a').on('click', function () {
		//	return false;
		//});

		$('.closeMenu a').on('click', function () {
			return false;
		});
		//Hide overlay after opacity transition
		$('.fullscreenOverlay').parent().bind('transitionend', function (e) {
			if (e.target.id == 'fullscreenOverlay') {
				$('.fullscreenOverlay').hide();
			}
		});

		menu.headerHeight = $('header').outerHeight(true);
		menu.headerOffset = $('header').offset().top;
		menu.stickyMenu();
	},

	stickyMenu: function () {
		if ($('header.baseHeader').length > 0) {

			var lastHeight = window.scrollY;
			var isSticky = false;
			$(window).scroll(function () {
				var windowTop = window.scrollY;
				if (windowTop > menu.headerHeight) {
					if (!isSticky) {
						$('header').css('top', (menu.headerHeight * -1)).css('position', 'fixed');
						menu.headerOffset = (menu.headerHeight * -1);
						isSticky = true;
					}
					if (lastHeight > windowTop) {
						$('header').css('top', '0');
						menu.headerOffset = 0;
						$('header').addClass('animate');

						$('.navigation').css('padding-top', 0);
						$('.shape-overlays').css('top', 0);
					} else if (lastHeight < windowTop) {
						$('header').css('top', (menu.headerHeight * -1));
						menu.headerOffset = (menu.headerHeight * -1);

						$('.navigation').css('padding-top', menu.headerOffset * -1);
						$('.shape-overlays').css('top', menu.headerOffset * -1);
					}
				} else if (windowTop === 0) {
					if (isSticky) {
						$('header').removeClass('animate');
						menu.headerOffset = 0;
						$('header').css('position', 'absolute').css('top', '0');
						isSticky = false;
					}
				}
				lastHeight = window.scrollY;
			});
		}
	}
}

var quickmenu = {
	floatingWindow: null,

	init: function () {
		if (document.getElementById('quickmenu')) {
			quickmenu.floatingWindow = document.querySelector('#quickmenu .floatingWindow');

			quickmenu.setEventListeners();
		}
	},

	setEventListeners: function () {
		document.querySelectorAll('#quickmenu a').forEach(a => {
			a.addEventListener('focus', function () {
				quickmenu.focus(a);
			});

			a.addEventListener('blur', function () {
				quickmenu.blur(a);
			});
		});

		if (document.querySelector('#quickmenu a[href="#menu"]')) {
			document.querySelector('#quickmenu a[href="#menu"]').addEventListener('click', e => {
				quickmenu.blur(document.querySelector('#quickmenu a[href="#menu"]'));
				document.querySelector('.openMenu a').click();
				document.querySelector('.closeMenu a').focus();
			});
		}
	},

	focus: function (a) {
		quickmenu.floatingWindow.classList.add('active');
		quickmenu.floatingWindow.querySelector('span').innerHTML = a.innerHTML;
	},

	blur: function (a) {
		if ('activeElement' in document in HTMLElement.prototype) {
			if (!document.getElementById('quickmenu').contains(document.activeElement)) {
				quickmenu.floatingWindow.querySelector('span').innerHTML = '';
				quickmenu.floatingWindow.classList.remove('active');
			}
		} else {
			quickmenu.floatingWindow.querySelector('span').innerHTML = '';
			quickmenu.floatingWindow.classList.remove('active');
		}
	},
};

var backToTop = {
	init: function () {
		$('.backToTop').on('click', function () {
			$("html, body").animate({
				scrollTop: $("body").offset().top
			}, 500);
		});
	}
}



var gallery = {
	endMargin: 0,
	endOverlayMargin: 0,
	endMaxHeight: 0,
	init: function () {
		if ($('.galleryOverlay').length > 0) {
			//gallery.initNav(0);
			gallery.initButtons();
		}
	},
	initNav: function (pos) {
		var slideShowCount = 3;
		var remainingSlides = 0;
		var galleryItems = $("ul.galleryItems li");
		if (galleryItems.length > slideShowCount) {
			remainingSlides = galleryItems.length - slideShowCount;
			if (!site.checkIfResponsive()) {
				$("ul.galleryItems").css("width", ((galleryItems.length + 1) * 310) + "px");

				if (remainingSlides > 0) {
					$("div.galleryPanel div.rightArrowHolder").addClass('show');

					var endPos = -Math.abs((remainingSlides) * 310);

					if ($(window).width() > 768) {
						if (pos <= endPos) {
							$("div.galleryPanel div.rightArrowHolder").removeClass('show');
						}
					}
				}
				if (pos >= 0) {
					$("div.gallery a.icon-arrow-left").hide();

				} else {
					$("div.gallery a.icon-arrow-left").show();
				}

			} else if (site.checkIfResponsive()) {
				if (pos == 0) {
					$('.galleryWrapper').css("max-height", (slideShowCount * 160) + "px");
					gallery.endMaxHeight = (slideShowCount * 160);
				}
				$("ul.galleryItems").css("height", (galleryItems.length * 160) + "px");

				if (remainingSlides > 0) {
					$("div.gallery a.icon-arrow-down").show();

					var endPos = -Math.abs((slideShowCount + remainingSlides) * 160);
					if (pos >= (galleryItems.length * 160)) {
						$("div.gallery a.icon-arrow-down").hide();
					}
				}
			}
		}
		gallery.endMargin = pos.toString().replace("px", "");
	},
	initOverlayNav: function (pos) {
		var slideShowCount = 1;
		var remainingSlides = 0;
		var galleryItems = $("ul.overlayGalleryItems li");
		if (galleryItems.length > slideShowCount) {
			remainingSlides = galleryItems.length - slideShowCount;
			//get width of container for reference minus padding for desktop
			var containerwidth = $('.gallerySlider').width();

			$("ul.overlayGalleryItems").css("width", ((galleryItems.length + 1) * containerwidth) + "px");
			$('ul.overlayGalleryItems li').css("width", containerwidth);

			if (remainingSlides > 0) {
				$("section.galleryOverlay a.icon-arrow-right").show();

				var endPos = -Math.abs((remainingSlides) * containerwidth);
				if (pos <= endPos) {
					$("section.galleryOverlay a.icon-arrow-right").hide();
				}

			}
			if (pos >= 0) {
				$("section.galleryOverlay a.icon-arrow-left").hide();

			} else {
				$("section.galleryOverlay a.icon-arrow-left").show();
			}
		}
		gallery.endOverlayMargin = pos.toString().replace("px", "");
	},
	initButtons: function () {
		$(".galleryItem, .mainImageHolder").on('click', function (e) {
			e.target.closest(".slider").parentElement.querySelector('.galleryOverlay').classList.add('show');
			var galleryImg = $("#" + $(this).data('idref'));
			var newMargin = -(galleryImg.index() * $('.gallerySlider').width());
			// e.target.closest(".slider").parentElement.querySelector('.galleryOverlay ul.overlayGalleryItems').css("margin-left", newMargin);

			$('ul.overlayGalleryItems').css("margin-left", newMargin);
			gallery.initOverlayNav(newMargin);
			slimmageTools.reload();
		});

		$("section.galleryOverlay a.btn-close").on('click', function (e) {
			$("section.galleryOverlay").removeClass("show");
			$('section.content').removeClass('galleryOpen');
		});

		$("section.galleryOverlay a.icon-arrow-right").on('click', function (e) {
			gallery.navigateOverlayRight();
		});

		$("section.galleryOverlay a.icon-arrow-left").on('click', function (e) {
			gallery.navigateOverlayLeft();
		});


	},
	navigateLeft: function () {
		var slider = $('ul.galleryItems');
		var currentMargin = slider.css('margin-left').replace("px", "");
		if (gallery.endMargin == currentMargin) {
			var newMargin = parseInt(currentMargin) + 310;
			slider.css('margin-left', newMargin + 'px');
			gallery.initNav(newMargin);
		}
	},
	navigateRight: function () {
		var slider = $('ul.galleryItems');
		var currentMargin = slider.css('margin-left').replace("px", "");
		if (gallery.endMargin == currentMargin) {
			var newMargin = parseInt(currentMargin) - 310;
			slider.css('margin-left', newMargin + 'px');
			gallery.initNav(newMargin);
		}
	},
	navigateOverlayLeft: function () {
		var slider = $('ul.overlayGalleryItems');
		var currentMargin = slider.css('margin-left').replace("px", "");
		if (gallery.endOverlayMargin == currentMargin) {
			var newMargin = parseInt(currentMargin) + $('.gallerySlider').width();
			slider.css('margin-left', newMargin + 'px');
			gallery.initOverlayNav(newMargin);
		}
	},
	navigateOverlayRight: function () {
		var slider = $('ul.overlayGalleryItems');
		var currentMargin = slider.css('margin-left').replace("px", "");
		if (gallery.endOverlayMargin == currentMargin) {
			var newMargin = parseInt(currentMargin) - $('.gallerySlider').width();
			slider.css('margin-left', newMargin + 'px');
			gallery.initOverlayNav(newMargin);
		}
	},
	slideDown: function () {
		var wrapper = $('.galleryWrapper');
		var currentHeight = wrapper.height();
		var newHeight = parseInt(currentHeight) + 480;
		$('.galleryWrapper').css("max-height", newHeight + "px");
		gallery.initNav(newHeight);
	},
}


var homePageLayover = {
	init: function () {
		var $win = $(window);
		//var wh = $(window).height() + 50;
		//var ww = $(window).width();


		$('.moveOn').click(function () {
			$('body').removeClass('overlayOpen');
		});

		$($win).on('scroll', function (e) {
			$('body').removeClass('overlayOpen');
		});
	}
}

var imageRepositoryLanding = {
	currentProgress: 0,
	slideTime: 5000,
	timer: {},
	init: function () {
		if ($('.highlightImageRepoItem').length > 0) {
			imageRepositoryLanding.startHighlightCycle();
		}
	},

	startHighlightCycle: function () {
		clearInterval(imageRepositoryLanding.timer);
		imageRepositoryLanding.timer = setInterval(imageRepositoryLanding.highlightCycle, (imageRepositoryLanding.slideTime / 100));

	},
	highlightCycle: function () {
		if (!$('.highlightImageRepoItem:last').parent().is(':hover')) {
			if (imageRepositoryLanding.currentProgress >= 100) {
				imageRepositoryLanding.currentProgress = 0;
				$('.highlightImageRepoItem:last').after($('.highlightImageRepoItem:first'));

			} else {
				imageRepositoryLanding.currentProgress++;
			}
		}
	}
}

var faq = {
	init: function () {
		if ($('.faqItem').length > 0) {
			faq.handleFaqClick();
		}
	},
	handleFaqClick: function () {
		$('.faqItem').on('click', function () {
			var currentActive = $('.faqItem.open');
			$(this).addClass('open');
			$(this).attr('title', 'Sluit het item');
			currentActive.removeClass('open');
			currentActive.attr('title', 'Open het item');
		});

		$('.faqItem').on('keydown', function (e) {
			if (e.keyCode == 13) {
				var currentActive = $('.faqItem.open');
				$(this).addClass('open');
				$(this).attr('title', 'Sluit het item');
				currentActive.removeClass('open');
				currentActive.attr('title', 'Open het item');
			}
		});
	}
}

const closeButton = document.querySelectorAll("header .icon.closeSearch");
var search = {
	init: function () {
		var typingTimer;
		var doneTypingInterval = 5000;

		if ($('.searchInput').length > 0) {

			$('.searchInput').focus(function (e) {
				search.openLayer();
			});

			$('.search svg.search').on('click', function () {
				if($('.searchInput').val()) {
					$('.searchForm').trigger( "submit" );
				}else{
					$('.searchInput').focus();
				}
			});

			$('.icon.closeSearch').on('click', function () {
				$('.searchInput').val('');
				$('body, .searchOverlay').removeClass('openTips');
				$('.searchTips a').attr('tabindex', '-1');
			});

			// Making sure search only closes if the user explicitly leaves it
			['click', 'focus'].forEach(eventName => {
				$(document).on(eventName, e => {
					if ((!$('.searchOverlay')[0].contains(e.target) && !$('.searchAndMeta')[0].contains(e.target))) {
						$('body, .searchOverlay').removeClass('openTips');
						$('.searchTips a').attr('tabindex', '-1');
					}
				});
			});

			$('.searchInput').keydown(function () {
				clearTimeout(typingTimer);
			});

			$('.searchInput').keyup(function (e) {
				var input = $(this).val();

				if (e.keyCode === 27) {
					$('body, .searchOverlay').removeClass('openTips');
					$('body, .searchOverlay').removeClass('openResults');

					$('.searchTips a').attr('tabindex', '-1');
				}

				console.log(e.keyCode);

				if (input.length >= 3) {
					$.get("/umbraco/surface/LayoutSurface/autocomplete?q=" + input, function (data) {
						$(".autocomplete").html(data);

						if (input !== '' && input !== ' ') {
							var pattern = new RegExp(input, "gi");
						}
						$('a.result').each(function (i) {
							var str = this;
							var orgText = $(str).text();
							orgText = orgText.replace(pattern, function ($1) {
								return "<span class=\"highlight\">" + $1 + "</span>";
							});
							$(str).html(orgText);
						});
					});

					$('body, .searchOverlay').addClass('openResults');
				} else {
					if (input.length == 0) {
						$('body, .searchOverlay').removeClass('openResults');
					}
				}
			});

			$(document).on('keyup', '.searchResults, .searchTips, .searchInput', function (e) {
				if (e.keyCode === 27) {
					$('body, .searchOverlay').removeClass('openTips');
					$('body, .searchOverlay').removeClass('openResults');

					$('.searchTips a').attr('tabindex', '-1');
				}
			});

			$('.searchMobile svg').on('click', function () {
				$('body').toggleClass('openMobileSearch');
			});
		};
	},
	openLayer: function () {
		if ($('.searchInput').length > 0) {

			$('body, .searchOverlay').addClass('openTips');
			$('.searchTips a').attr('tabindex', '0');
			$('body').removeClass('overlayOpen');

			var input = $('.searchInput').val();
			if (input.length > 4) {
				$.get("/umbraco/surface/LayoutSurface/autocomplete?q=" + input, function (data) {
					$(".autocomplete").html(data);

					if (input !== '' && input !== ' ') {
						var pattern = new RegExp(input, "gi");
					}
					$('a.result').each(function (i) {
						var str = this;
						var orgText = $(str).text();
						orgText = orgText.replace(pattern, function ($1) {
							return "<span class=\"highlight\">" + $1 + "</span>";
						});
						$(str).html(orgText);
					});
				});

				$('body, .searchOverlay').addClass('openResults');
			}
		}
	}
}

var overviewMenu = {

	menuOffset: 0,
	init: function () {
		if ($('.originalOverviewMenuHolder').length > 0) {
			overviewMenu.menuOffset = $('.originalOverviewMenuHolder').offset().top;
			dropdownFiltering.init();
			switchView.init();
			overviewMenu.stickyMenu();
		}
	},

	stickyMenu: function () {
		var isSticky = false;
		$(window).scroll(function () {
			var windowTop = window.scrollY;
			if (windowTop > (overviewMenu.menuOffset - (menu.headerHeight + menu.headerOffset))) {
				if (!isSticky) {

					$('.originalOverviewMenuHolder').height($('.overviewMenu').outerHeight(true));
					$('.originalOverviewMenuHolder .overviewMenu').detach().appendTo('.stickyOverviewMenuHolder');
					isSticky = true;
				}
			} else {
				if (isSticky) {
					$('.overviewMenu').detach().appendTo('.originalOverviewMenuHolder');
					$('.originalOverviewMenuHolder').css('height', '');
					isSticky = false;
				}
			}
		});
	}
}

var switchView = {
	init: function () {
		// Auto switch to Grid view for tablet or higher
		$('body').addClass("blockView");

		$('.overviewMenu .overviewMenuButton').on('click', function () {
			$('.overviewItems').fadeOut(function () {
				$('body').toggleClass("blockView");
				$('.overviewItems').fadeIn();
			});
		});
	}
}

var slider = {
	init: function () {
		var slideWidth = $('.slider').width();
		$('.slider').each(function (s) {
			$("body").keydown(function (e) {
				if (e.keyCode == 37) {
					slider.slideLeft(slideWidth);
				} else if (e.keyCode == 39) {
					slider.slideRight(slideWidth);
				}
			});

			$('a.arrow.left').on('click', function () {
				slider.slideLeft(slideWidth);
			});

			$('a.arrow.right').on('click', function () {
				slider.slideRight(slideWidth);
			});
		});
	},
	slideLeft: function (w) {
		var active = $('.slide.active');
		var prev = $(active).prev();

		if (prev.length > 0) {
			$('.slider .items').animate({
				'marginLeft': '+=' + w + 'px'
			}, 500);

			$(active).removeClass('active');
			$(prev).addClass('active');
		}
	},
	slideRight: function (w) {
		var active = $('.slide.active');
		var next = $(active).next();

		if (next.length > 0) {
			$('.slider .items').animate({
				'marginLeft': '-=' + w + 'px'
			}, 500);

			$(active).removeClass('active');
			$(next).addClass('active');
		}
	}

}

var dropdownFiltering = {
	users: [],
	subjects: [],
	onlyCourses: false,
	supportsSessionStorage: false,
	init: function () {
		$.each($('.dropdownFilter .filterName'), function (k, v) {
			$(v).on('click', function () {
				var currentActive = $('.dropdownFilter.active');
				$(v).parent().addClass('active');
				$(v).parent().children('.dropDown').css('max-height', $(v).parent().children('.dropDown')[0].scrollHeight + 1 + 'px');
				$(v).parent().find('label, a').attr('tabindex', '0');
				hideOnClickOutside($(v).parent());
				currentActive.removeClass('active');
				currentActive.children('.dropDown').css('max-height', '0');
				currentActive.find('label, a').attr('tabindex', '-1');
			});

			$(v).parent().find('.resetFilter').on('click', function (evt) {
				evt.preventDefault();
				$(this).closest('.dropdownFilter').find('input').prop('checked', false).change();
			});


			dropdownFiltering.filterOnDataTypes();

		});

		dropdownFiltering.initStorage();

		$('.dropdownFilter.users input').on('change', function () {
			dropdownFiltering.updateFilters(dropdownFiltering.users, $(this), "user");
		});

		$('.dropdownFilter.subjects input').on('change', function () {
			dropdownFiltering.updateFilters(dropdownFiltering.subjects, $(this), "subject");
		});

		$('.selectItem.onlyCourses input').on('change', function () {
			dropdownFiltering.onlyCourses = $(this).is(':checked');
			dropdownFiltering.filterOnDataTypes();
		});

		if (dropdownFiltering.supportsSessionStorage) {
			dropdownFiltering.filterOnDataTypes();
		}
	},

	updateFilters: function (array, input, dataname) {
		if ($(input).is(":checked")) {
			array.push($(input).data(dataname));
		} else {
			if (array.indexOf($(input).data(dataname)) > -1) {
				array.splice(array.indexOf($(input).data(dataname)), 1);
			}

		}

		dropdownFiltering.updateSessionStorage();
		dropdownFiltering.filterOnDataTypes();
	},

	filterOnDataTypes: function () {
		$('.overviewItems').fadeOut(function () {
			var toShow = $('.overviewItem').toArray();
			if (dropdownFiltering.users.length > 0) {
				$.each(toShow.slice(), function () {
					// for each overview item, create array from comma separated values
					var userArray = $(this).data("user").split(',');
					var shouldBeFiltered = true;
					//if ANY of them hit, it should not be filtered
					userArray.some((user) => {
						if (dropdownFiltering.users.indexOf(user) > -1) {
							shouldBeFiltered = false;
							return true;
						}
					});
					//filter
					if (shouldBeFiltered) {
						$(this).addClass("filtered");
						if (toShow.indexOf(this) !== -1) {
							toShow.splice(toShow.indexOf(this), 1);
						}
					}
				});
			}
			if (dropdownFiltering.subjects.length > 0) {
				$.each(toShow.slice(), function () {

					var subjectArray = $(this).data("subject").split(',');
					var shouldBeFiltered = true;

					$(this).find(".subject").removeClass("show");

					subjectArray.some((subject) => {
						if (dropdownFiltering.subjects.indexOf(subject) > -1) {
							shouldBeFiltered = false;
							$(this).find(".subject." + subject).addClass("show");
							return true;
						}
					});

					if (shouldBeFiltered) {
						$(this).addClass("filtered");
						if (toShow.indexOf(this) !== -1) {
							toShow.splice(toShow.indexOf(this), 1);
						}
					}
				});
			} else {
				$.each(toShow.slice(), function () {
					$(this).find(".subject").removeClass("show");
					$($(this).find(".subject")[0]).addClass("show");
				});
			}

			if (dropdownFiltering.onlyCourses === true) {
				$.each(toShow.slice(), function () {
					if ($(this).data("iscourse") !== 'True') {
						$(this).addClass("filtered");
						if (toShow.indexOf(this) !== -1) {
							toShow.splice(toShow.indexOf(this), 1);
						}
					}
				});
			}

			if (dropdownFiltering.users.length <= 0 && dropdownFiltering.subjects.length <= 0 && dropdownFiltering.onlyCourses === false) {
				$.each(toShow.slice(), function () {
					if ($(this).data("hideunfiltered") === 'True') {
						$(this).addClass("filtered");
						if (toShow.indexOf(this) !== -1) {
							toShow.splice(toShow.indexOf(this), 1);
						}
					}
				});
			}

			$(toShow).removeClass("filtered");

			$('.overviewItems').fadeIn();
		});

	},

	initStorage: function () {
		if (typeof (Storage) !== 'undefined') {
			dropdownFiltering.supportsSessionStorage = true;

			if (sessionStorage.getItem(location.pathname) === null) {
				sessionStorage.setItem(location.pathname, JSON.stringify({
					users: [],
					subjects: []
				}));
			}

			dropdownFiltering.subjects = JSON.parse(sessionStorage.getItem(location.pathname)).subjects;
			dropdownFiltering.users = JSON.parse(sessionStorage.getItem(location.pathname)).users;

			dropdownFiltering.subjects.forEach(name => {
				$('.dropdownFilter.subjects input[data-subject="' + name + '"]').prop('checked', true);
			});

			dropdownFiltering.users.forEach(name => {
				$('.dropdownFilter.users input[data-subject="' + name + '"]').prop('checked', true);
			});
		}
	},

	updateSessionStorage: function () {
		if (dropdownFiltering.supportsSessionStorage) {
			sessionStorage.setItem(location.pathname, JSON.stringify({
				subjects: dropdownFiltering.subjects,
				users: dropdownFiltering.users
			}));
		}
	}
}

$(window).resize(function () {
	slimmageTools.reload();
	if ($('header').length > 0) {
		menu.headerHeight = $('header').outerHeight(true);
	}
	if ($('.originalOverviewMenuHolder').length > 0) {
		overviewMenu.menuOffset = $('.originalOverviewMenuHolder').offset().top;
	}
});

var slimmageTools = {
	reload: function () {
		var s = window['slimmage'];
		if (s !== undefined) {
			s.cr(500);
		}
	}
};

var svgFix = {
	init: function () {
		if ($('.svgCheatsheet').length === 0) {
			svgFix.execute();

			$(window).on('resize', function () {
				svgFix.execute();
			});
		}
	},

	execute: function () {
		$('svg').each(function () {
			var viewbox = $(this)[0].getAttribute('viewBox');
			var aspectRatio = $(this)[0].getAttribute('preserveAspectRatio');

			if (viewbox && !aspectRatio) {
				var array = viewbox.split(' ');
				var elWidth = $(this).width();
				aspectRatio = array[2] / array[3];
				var newHeight = elWidth / aspectRatio;
				$(this).css('height', newHeight);
			}
		});
	}
};

var formFixes = {
	init: function () {
		$('span[class^="field-validation"]').attr("role", "alert");

		document.querySelectorAll('label[for]').forEach(label => {
			label.addEventListener('keydown', e => {
				switch (e.key.toLowerCase()) {
					case ' ':
						e.preventDefault();
					case 'space':
					case 'enter':
						label.click();
						break;
				}
			});
		});
	}
}

function hideOnClickOutside(selector) {
	var outsideClickListener = function (event) {
		if (!$(event.target).closest(selector).length) {
			if ($(selector).is(':visible')) {
				$(selector).removeClass('active');
				$(selector).children('.dropDown').css('max-height', '0');
				removeClickListener();
			}
		}
	};

	var removeClickListener = function () {
		document.removeEventListener('click', outsideClickListener);
	};

	document.addEventListener('click', outsideClickListener);
}

var selectBox = {
	init: function (selector) {
		if (selector == null) {
			selector = $('select');
		}

		selector.each(function () {
			var $this = $(this),
				enabled = true,
				numberOfOptions = $(this).children('option').length;

			$this.parent('label').addClass('select');
			$this.addClass('hide');
			$this.after('<div class="styledSelect"></div>');

			var $styledSelect = $this.next('div.styledSelect');
			$styledSelect.text(($this.children('option:selected').length > 0) ? $this.children('option:selected').text() : $this.children('option').eq(0).text());

			if ($this.is(':disabled'))
				enabled = false;

			if (enabled) {
				var $list = $('<ul />', {
					'class': 'options'
				}).insertAfter($styledSelect);

				for (var i = 0; i < numberOfOptions; i++) {
					$('<li />', {
						text: $this.children('option').eq(i).text(),
						rel: $this.children('option').eq(i).val(),
						'class': ($this.children('option').eq(i).is(':selected')) ? 'selected' : ''
					}).appendTo($list);
				}

				var $listItems = $list.children('li');

				$styledSelect.click(function (e) {
					e.stopPropagation();
					$('div.styledSelect.active').each(function () {
						$(this).removeClass('active');
					});

					$(this).toggleClass('active').next('ul.options').toggle();
				});

				$listItems.click(function (e) {
					e.stopPropagation();
					$styledSelect.text($(this).text()).removeClass('active');
					$this.val($(this).attr('rel')).change();
					$(this).addClass('selected').siblings().removeClass('selected');
					$list.hide();
				});

				$(document).click(function (e) {
					if ($(e.target).parent('label').length <= 0) {
						$styledSelect.removeClass('active');
						$list.hide();
					}
				});
			} else {
				$styledSelect.addClass('disabled');
			}
		});
	}
}

var ResponsiveTable = {
	selector: '.content .grid table',

	init: function () {
		ResponsiveTable.polyfill();

		document.querySelectorAll(ResponsiveTable.selector).forEach(table => {
			ResponsiveTable.prepare(table);
		});
	},

	polyfill: function () {
		// Foreach
		if ('NodeList' in window && !NodeList.prototype.forEach) {
			console.info('polyfill for IE11');
			NodeList.prototype.forEach = function (callback, thisArg) {
				thisArg = thisArg || window;
				for (var i = 0; i < this.length; i++) {
					callback.call(thisArg, this[i], i, this);
				}
			};
		}

		// Prepend
		(function (arr) {
			arr.forEach(function (item) {
				if (item.hasOwnProperty('prepend')) {
					return;
				}
				Object.defineProperty(item, 'prepend', {
					configurable: true,
					enumerable: true,
					writable: true,
					value: function prepend() {
						var argArr = Array.prototype.slice.call(arguments),
							docFrag = document.createDocumentFragment();

						argArr.forEach(function (argItem) {
							var isNode = argItem instanceof Node;
							docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
						});

						this.insertBefore(docFrag, this.firstChild);
					}
				});
			});
		})([Element.prototype, Document.prototype, DocumentFragment.prototype]);
	},

	prepare: function (table) {
		if (!table.querySelectorAll('thead').length) {
			ResponsiveTable.createTableHead(table);
		}

		ResponsiveTable.fillEmptyCells(table);
		ResponsiveTable.addRowHeaders(table);
	},

	createTableHead: function (table) {
		let thead = document.createElement('thead');
		thead.appendChild(table.querySelector('tr'));
		table.prepend(thead);
		thead.querySelectorAll('td').forEach(td => {
			let th = document.createElement('th');
			for (let i = 0; i < td.attributes.length; i++) {
				th.setAttribute(td.attributes.item(i).nodeName, td.attributes.item(i).nodeValue);
			}
			th.innerHTML = td.innerHTML;
			td.parentElement.replaceChild(th, td);
		});
	},

	addRowHeaders: function (table) {
		// Create an array of the thead values        
		let headers = [];
		table.querySelectorAll('thead tr th').forEach(header => headers.push(header.innerHTML));

		// Prepend headers to all tbody rows
		table.querySelectorAll('tbody tr').forEach(row => {
			headers.forEach((header, index) => {
				let headerSpan = document.createElement('span');
				headerSpan.classList.add('mobileHeader');
				headerSpan.innerHTML = header;

				row.querySelectorAll('td')[index].prepend(headerSpan);
			});
		});
	},

	fillEmptyCells: function (table) {
		// When a column has mostly the same values, any same following cell is often kept empty
		// Due to how the responsive tables work, it's better to keep them filled in on mobile

		let currentRowValues = [];
		table.querySelectorAll('tbody tr').forEach((row, index) => {
			/* console.log(`Starting row ${index + 1}`); */
			row.querySelectorAll('td').forEach((cell, col) => {
				if (cell.innerHTML.trim() !== '' && cell.innerHTML.trim() !== '&nbsp;') {
					currentRowValues[col] = cell.innerHTML;
				} else if (currentRowValues[col] !== undefined) {
					/* console.log(`Column ${col + 1} is empty, filling with "${currentRowValues[col]}"`); */

					let tempDiv = document.createElement('div');
					tempDiv.innerHTML = currentRowValues[col];

					cell.removeChild(cell.firstChild);
					cell.appendChild(tempDiv.firstChild);
					cell.classList.add('wasEmpty');
					/* console.log(cell); */
				}
			});
			/* console.log(`---------------------------------------------`); */
		});
	},
}

var presentationPage = {
	pages: 0,
	currentPage: 0,

	init: function () {
		if ($('body').hasClass('presentationPage') && $('.hero > ul').length) {
			presentationPage.initCarousel();
		}
	},

	initCarousel: function () {
		presentationPage.pages = $('.hero > ul > li').length;
		presentationPage.currentPage = 1;

		$('.hero .controls .previous').on('click', function () {
			presentationPage.previous();
		});

		$('.hero .controls .previous').on('keydown', function (e) {
			if (e.keyCode == 13) {
				presentationPage.previous();
			}
		});

		$('.hero .controls .next').on('click', function () {
			presentationPage.next();
		});

		// $('.hero .controls .next').on('keydown', function (e) {
		// 	if (e.keyCode == 13) {
		// 		presentationPage.next();
		// 	}
		// });

		$('.hero').keydown(function (e) {
			switch (e.which) {
				case 37: // left
					presentationPage.previous();
					break;
				case 39: // right
					presentationPage.next();
					break;
				case 27: // Escape
					if ($('.hero .current').hasClass('active')) {
						presentationPage.toggleHeroMenu();
					}
					break;
				default:
					return;
			}
			e.preventDefault();
		});

		presentationPage.checkButtons();
		presentationPage.updateCaption();

		console.log($('.hero > ul > li').length);

		if ($('.hero > ul > li').length <= 1) {
			$('.captionContainer .caption').find('.total').parent().addClass('hidden');
		}

		$('.hero .current').on('click', function () {
			presentationPage.toggleHeroMenu();
		});
	},

	toggleHeroMenu: function () {
		$('.hero .current').toggleClass('active');

		if ($('.hero .current').hasClass('active')) {
			$('.hero nav a').attr('tabindex', '0');
			$('.hero .current + ul').attr('aria-expanded', true);
		} else {
			$('.hero nav a').attr('tabindex', '-1');
			$('.hero .current + ul').attr('aria-expanded', false);
		}
	},

	next: function () {
		if (presentationPage.currentPage < presentationPage.pages) {
			presentationPage.currentPage = presentationPage.currentPage + 1;
			presentationPage.goToPage(presentationPage.currentPage);
		}
	},

	previous: function () {
		if (presentationPage.currentPage > 1) {
			presentationPage.currentPage = presentationPage.currentPage - 1;
			presentationPage.goToPage(presentationPage.currentPage);
		}
	},

	goToPage: function (page) {
		if (page < 1) {
			page = 1;
		}

		if (page > presentationPage.pages) {
			page = presentationPage.pages;
		}

		$('.hero > ul > li').css('transform', 'translateX(' + (page - 1) * -100 + '%)');

		presentationPage.currentPage = page;
		presentationPage.checkButtons();
		presentationPage.updateCaption();
	},

	checkButtons: function () {
		if (presentationPage.currentPage < 2) {
			$('.hero .controls .previous').addClass('disabled');
			$('.hero .controls .previous').attr('tabindex', '-1');

		} else {
			$('.hero .controls .previous').removeClass('disabled');
			$('.hero .controls .previous').attr('tabindex', '0');
		}

		if (presentationPage.currentPage >= presentationPage.pages) {
			$('.hero .controls .next').addClass('disabled');
			$('.hero .controls .next').attr('tabindex', '-1');
		} else {
			$('.hero .controls .next').removeClass('disabled');
			$('.hero .controls .next').attr('tabindex', '0');
		}

		// Disable all links in the pages
		$('.hero > ul > li').children('a, button').attr('tabindex', '-1');

		// Enable all links in the current page
		$('.hero > ul > li:nth-child(' + presentationPage.currentPage + ')').children('a, button').attr('tabindex', '0');
	},

	updateCaption: function () {
		var item = $('.hero > ul > li')[presentationPage.currentPage - 1];

		$('.captionContainer .caption .current').html(presentationPage.currentPage);
		$('.captionContainer .caption .total').html(presentationPage.pages);
		$('.captionContainer .caption .text').html($(item).find('.caption').html());
	}
};

var alertRibbon = {
	init: function () {
		if ($.cookie && ($.cookie('hideAlertRibbon') == undefined || $.cookie('hideAlertRibbon') == false)) {
			alertRibbon.show();
		}

		$('#alertRibbon .close').on('click', function () {
			alertRibbon.hide();
		});

		alertRibbon.moveHeaderAndContent();

		window.addEventListener('resize', function (event) {
			alertRibbon.moveHeaderAndContent();
		});
	},

	moveHeaderAndContent: function () {
		$('header').css('transform', "translate( -50%, " + $('#alertRibbon').height() + "px)");
		$('section.content').css('transform', "translateY(0) translate( 0, " + $('#alertRibbon').height() + "px)");
		if ($('#alertRibbon').height() == 0) {
			setTimeout(() => {
				$('section.content').css('transform', '');
			}, 400);
		}
	},

	show: function () {
		$('#alertRibbon').removeClass('hidden');
		$('#alertRibbon').attr('aria-expanded', true);
		$('#alertRibbon').css('height', "auto");
	},

	hide: function () {
		$('#alertRibbon').addClass('hidden');
		$('#alertRibbon').attr('aria-expanded', false);
		$('#alertRibbon').css('height', "0px");
		alertRibbon.moveHeaderAndContent()
		if ($.cookie) {
			$.cookie('hideAlertRibbon', true, {});
		}
	}
};

var share = {
	selector: '.shareContainer',
	url: null,
	description: null,
	image: null,
	tagTitle: null,
	mobile: null,
	analytics: true,
	order: false, // If order should be different than default: fill with an array like this: ['facebook', 'linkedin'] etc.

	medias: {
		whatsapp: {
			name: 'WhatsApp',
			enabled: true,
			icon: `<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
          d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
</svg>`,
			mobileOnly: true
		},
		facebook: {
			name: 'Facebook',
			enabled: true,
			icon: `<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
          d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z" />
</svg>`,
			mobileOnly: false
		},
		linkedin: {
			name: 'LinkedIn',
			enabled: true,
			icon: `<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
          d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
</svg>`,
			mobileOnly: false
		},
		twitter: {
			name: 'Twitter (X)',
			enabled: true,
			icon: `<svg viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-18jsvk2 r-rxcuwo r-1777fci r-m327ed r-494qqr">
			<g>
				<path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
			</g>
		</svg>`,
			mobileOnly: false
		},
		email: {
			name: 'e-mail',
			enabled: true,
			icon: `<svg role="image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330">
	<path
		  d="M173.871,177.097c-2.641,1.936-5.756,2.903-8.87,2.903c-3.116,0-6.23-0.967-8.871-2.903L30,84.602
		L0.001,62.603L0,275.001c0.001,8.284,6.716,15,15,15L315.001,290c8.285,0,15-6.716,15-14.999V62.602l-30.001,22L173.871,177.097z" />
	<polygon points="165.001,146.4 310.087,40.001 19.911,40" />
</svg>`,
			mobileOnly: false
		}
	},

	init: function () {
		if (share.url === null) {
			// Use current page
			share.url = window.location.href;
		}

		if (share.description === null) {
			share.description = share.autoObtainDescription();
		}

		if (share.mobile === null) {
			share.mobile = share.autoObtainMobile();
		}

		share.createShareButtons();
	},

	autoObtainDescription: function () {
		let metaDescription = null;
		let ogDescription = null;

		let metaTags = document.head.getElementsByTagName('meta');

		for (var metaIndex = 0; metaIndex < metaTags.length; metaIndex++) {
			if (metaTags[metaIndex].getAttribute('property') === 'description') {
				metaDescription = metaTags[metaIndex].getAttribute('content');
			}
			if (metaTags[metaIndex].getAttribute('property') === 'og:description') {
				ogDescription = metaTags[metaIndex].getAttribute('content');
			}
		}

		if (metaDescription === null && ogDescription === null) {
			return '';
		} else if (ogDescription !== null) {
			return ogDescription;
		} else {
			return metaDescription;
		}
	},

	autoObtainMobile: function () {
		return /iPhone|Android|Blackberry/i.test(navigator.userAgent);
	},

	obtainTagTitle: function (media) {
		if (share.tagTitle === null) {
			return `Deel deze pagina via ${share.medias[media].name}`;
		} else {
			return share.tagTitle.replace('MEDIA', share.medias[media].name);
		}
	},

	whatsapp: function () {
		return `whatsapp://send?text=${encodeURIComponent(share.url)}`;
	},

	facebook: function () {
		return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(share.url)}`;
	},

	twitter: function () {
		let url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(share.url)}`;
		if (share.description !== '') {
			url += `&text=${encodeURIComponent(share.description)}`;
		} else if (document.title) {
			url += `&text=${encodeURIComponent(document.title)}`;
		}
		return url;
	},

	linkedin: function () {
		let url = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(share.url)}&mini=true`;
		if (share.description !== '') {
			url += `&summary=${encodeURIComponent(share.description)}`;
		}
		return url;
	},

	email: function () {
		return `mailto:?body=${encodeURIComponent(share.url) + ' ' + share.description}&subject=Bekijk deze interessante pagina: ${document.title}`;
	},

	createShareButtons: function () {
		let list;
		if (share.order) {
			list = share.order;
		} else {
			list = Object.keys(share.medias);
		}

		list.forEach(media => {
			if (this.medias[media].enabled) {
				let renderIcon = true;
				if (share.medias[media].mobileOnly && !share.mobile) {
					renderIcon = false;
				}

				if (renderIcon) {
					let tag = document.createElement('a');
					tag.setAttribute('target', '_blank');
					tag.setAttribute('rel', 'noopener');
					tag.href = share[media]();
					tag.title = share.obtainTagTitle(media);
					tag.innerHTML = share.medias[media].icon;
					tag.setAttribute('aria-label', `Klik om deze pagina te delen via ${share.medias[media].name}`);

					const containerElements = document.querySelectorAll(share.selector);
					for (var index = 0; index < containerElements.length; index++) {
						share.bindEvents(tag, media);
						containerElements[index].appendChild(tag);
					}
				}
			}
		});
	},

	bindEvents: function (link, media) {
		link.addEventListener('click', e => {
			e.preventDefault();
			window.open(link.href, 'NewWindow', `menubar=no,toolbar=no,status=no,width=570,height=570,top=${(screen.height - 570) / 2},left=${(screen.width - 570) / 2}`);

			if (share.analytics) {
				if (window._gat) {
					//console.log(`window._gaq.push(['_trackEvent', 'Social share', '${media}', '${this.url}']);`);
					window._gaq.push(['_trackEvent', 'Social share', media, share.url]);
				} else if (window.ga) {
					//console.log(`window.ga('send', 'event', 'Social share', '${media}', '${this.url}');`);
					window.ga('send', 'event', 'Social share', media, share.url);
				}
			}

			return false;
		});
	}
};

var addNewWindowTitles = {
	init: function () {
		let text = '(opent in een nieuw scherm)';
		if (document.querySelector('[data-new-window-text]')) {
			text = document.querySelector('[data-new-window-text]').dataset.newWindowText;
		}

		document.querySelectorAll('a[target="_blank"][title]').forEach(a => {
			a.setAttribute('title', `${a.getAttribute('title')} ${text}`.trim().replace(/  /g, " "));
		});
	}
};

jQuery.fn.highlight = function (str, className) {
	var regex = new RegExp(str, "gi");
	return this.each(function () {
		$(this).contents().filter(function () {
			return this.nodeType == 3 && regex.test(this.nodeValue);
		}).replaceWith(function () {
			return (this.nodeValue || "").replace(regex, function (match) {
				return "<span class=\"" + className + "\">" + match + "</span>";
			});
		});
	});
};

var Tippy = {
		init: function () {
			if (document.querySelectorAll('[data-tippy-content]').length || document.querySelectorAll('[data-tippy-source]').length) {
				try {
					tippy('[data-tippy-content]');

					if (document.querySelectorAll('[data-tippy-source]').forEach(source => {
							const target = document.querySelector(`[data-tippy-target="${source.dataset.tippySource}"]`);
							if (target) {
								tippy(source, {
									allowHTML: true,
									content: target.innerHTML,
									interactive: target.querySelectorAll('a, button').length > 0,
									trigger: 'click',
								});
							}
						}));
				} catch (e) {
					console.error(e);
				}
			}

		}
	};


	$(function () {
		$('html.no-js').removeClass('no-js');


		var ua = navigator.userAgent.toLowerCase();
		if (ua.indexOf('safari') !== -1) {
			if (ua.indexOf('chrome') > -1) {
				// skip this, it's chrome
			} else {
				$('html').addClass('safari');
			}
		}

		menu.init();
		quickmenu.init();
		homePageLayover.init();
		slimmageTools.reload();
		backToTop.init();
		slider.init();
		overviewMenu.init();
		selectBox.init($('.contourField select'));
		gallery.init();
		search.init();
		imageRepositoryLanding.init();
		faq.init();
		svgFix.init();
		formFixes.init();
		homepageAnimation.init();
		homepageNewsScroller.init();
		ResponsiveTable.init();
		presentationPage.init();
		alertRibbon.init();
		share.init();
		addNewWindowTitles.init();
		Tippy.init();
	});